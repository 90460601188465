import { useEffect, useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useSearchParams } from "react-router-dom";
import { friendlyFuelType, friendlyTransmission, friendlyVatMargin, friendlyAutoCategory, thousandNotationAndCeil } from "./assets/vehicleTranslate";

import { convertAccessoires } from "./assets/helpers";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  demo: {
      normal: 'https://brochure.sld.nl/Poppins-Regular.ttf',
      bold: 'https://brochure.sld.nl/Poppins-Bold.ttf',
      italics: 'https://brochure.sld.nl/Poppins-Italic.ttf',
      bolditalics: 'https://brochure.sld.nl/Poppins-Medium.ttf'
    },
  Fontello: {
      normal: "https://brochure.sld.nl/fontello.ttf"
  }
}

const App = () => {
  const [searchParams] = useSearchParams();
  const [id] = useState(searchParams.get("id"));  
  const [data, setData] = useState({
    make: "",
    model: "",
    type: ""
  });
  const [accessoryGroups, setAccessoryGroups] = useState([]);

  const [isLoaded, setIsLoaded] = useState(false);
 
  // var logoImageUrl = "https://brochure.sld.nl/SLD_logo.png"; // dealerDetail.logoImageUrl;
  const [vehicleImage, setVehicleImage] = useState("");

  const [logoIsLoaded, setLogoIsLoaded] = useState(false);
  const [dealerLogo, setDealerLogo] = useState("");

  const [highest3Months, setHighest3Months] = useState([]);
  const [highest3MonthsValues, setHighest3MonthsValues] = useState([]);
  const [highest3MonthsPayments, setHighest3MonthsPayments] = useState([]);
  const [bestChoice, setBestChoice] = useState([]);

  // if(/localhost/.test(props.hostname)) {
  //     logoImageUrl = "https://brochure.sld.nl/SLD_logo.png";
  // }

  
  // useEffect(() => {
  //     if(logoIsLoaded) return;
  //     (async ()=>{           
  //         const awaitedLogo = await getBase64ImageFromURL(logoImageUrl);
  //         setLogoIsLoaded(true);
  //         setDealerLogo(awaitedLogo);
  //         return dealerLogo;
  //     })();
  // },[dealerLogo]);

  useEffect(() => {
    if(!id) return;
    fetch(process.env.REACT_APP_SLD_API_BASE_URL+`/api/ExternalVehicle/GetById?number=${id}`, {
      headers: {
        "Content-Type": "application/json",
        "token": process.env.REACT_APP_WEBSITE_TOKEN
      }
    }).then(resp => resp.json())
    .then(data => {
      // console.log(data);
      setData(data);
      var image_url = data.vehicleImages[0]?.uploadURL || "";
      if(!image_url) image_url = "https://brochure.sld.nl/SLD_logo.png";
      setVehicleImage(image_url);
      var visibleMonths = [];
      var visibleMonthsValues = [];
      var visiblePayments = [];
      var bestChoiceArr = [];
      bestChoiceArr.push([{text: " "},{text: " "},{text: "Beste keuze!", alignment: "center", italics: true, fontSize: 14}]);


      if(data.maxDuration>=72) {
        bestChoiceArr = [];
        bestChoiceArr.push([{text: " "},{text: "Beste keuze!", alignment: "center", italics: true, fontSize: 14},{text: " "}]);
        visibleMonths.push({alignment: "center", bold: true, color: "white", fontSize: 16, text: "48 maanden"});
        visibleMonths.push({alignment: "center", bold: true, color: "white", fontSize: 16, text: "60 maanden"});
        visibleMonths.push({alignment: "center", bold: true, color: "white", fontSize: 16, text: "72 maanden"});
        visibleMonthsValues.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.monthlyAmount48 ? thousandNotationAndCeil(data.monthlyAmount48) : '')});
        visibleMonthsValues.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.monthlyAmount60 ? thousandNotationAndCeil(data.monthlyAmount60) : '')});
        visibleMonthsValues.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.monthlyAmount72 ? thousandNotationAndCeil(data.monthlyAmount72) : '')});
        visiblePayments.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.finalPayment48 ? thousandNotationAndCeil(data.finalPayment48) : '')});
        visiblePayments.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.finalPayment60 ? thousandNotationAndCeil(data.finalPayment60) : '')});
        visiblePayments.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.finalPayment72 ? thousandNotationAndCeil(data.finalPayment72) : '')});
      } else if (data.maxDuration===60) {
        visibleMonths.push({alignment: "center", bold: true, color: "white", fontSize: 16, text: "36 maanden"});
        visibleMonths.push({alignment: "center", bold: true, color: "white", fontSize: 16, text: "48 maanden"});
        visibleMonths.push({alignment: "center", bold: true, color: "white", fontSize: 16, text: "60 maanden"});
        visibleMonthsValues.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.monthlyAmount36 ? thousandNotationAndCeil(data.monthlyAmount36) : '')});
        visibleMonthsValues.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.monthlyAmount48 ? thousandNotationAndCeil(data.monthlyAmount48) : '')});
        visibleMonthsValues.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.monthlyAmount60 ? thousandNotationAndCeil(data.monthlyAmount60) : '')});
        visiblePayments.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.finalPayment36 ? thousandNotationAndCeil(data.finalPayment36) : '')});
        visiblePayments.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.finalPayment48 ? thousandNotationAndCeil(data.finalPayment48) : '')});
        visiblePayments.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.finalPayment60 ? thousandNotationAndCeil(data.finalPayment60) : '')});
      } else if (data.maxDuration===48) {
        visibleMonths.push({alignment: "center", bold: true, color: "white", fontSize: 16, text: "24 maanden"});
        visibleMonths.push({alignment: "center", bold: true, color: "white", fontSize: 16, text: "36 maanden"});
        visibleMonths.push({alignment: "center", bold: true, color: "white", fontSize: 16, text: "48 maanden"});
        visibleMonthsValues.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.monthlyAmount24 ? thousandNotationAndCeil(data.monthlyAmount24) : '')});
        visibleMonthsValues.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.monthlyAmount36 ? thousandNotationAndCeil(data.monthlyAmount36) : '')});
        visibleMonthsValues.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.monthlyAmount48 ? thousandNotationAndCeil(data.monthlyAmount48) : '')});
        visiblePayments.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.finalPayment24 ? thousandNotationAndCeil(data.finalPayment24) : '')});
        visiblePayments.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.finalPayment36 ? thousandNotationAndCeil(data.finalPayment36) : '')});
        visiblePayments.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.finalPayment48 ? thousandNotationAndCeil(data.finalPayment48) : '')});
      } else if (data.maxDuration===36) {
        visibleMonths.push({alignment: "center", bold: true, color: "white", fontSize: 16, text: "12 maanden"});
        visibleMonths.push({alignment: "center", bold: true, color: "white", fontSize: 16, text: "24 maanden"});
        visibleMonths.push({alignment: "center", bold: true, color: "white", fontSize: 16, text: "36 maanden"});
        visibleMonthsValues.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.monthlyAmount12 ? thousandNotationAndCeil(data.monthlyAmount12) : '')});
        visibleMonthsValues.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.monthlyAmount24 ? thousandNotationAndCeil(data.monthlyAmount24) : '')});
        visibleMonthsValues.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.monthlyAmount36 ? thousandNotationAndCeil(data.monthlyAmount36) : '')});
        visiblePayments.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.finalPayment12 ? thousandNotationAndCeil(data.finalPayment12) : '')});
        visiblePayments.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.finalPayment24 ? thousandNotationAndCeil(data.finalPayment24) : '')});
        visiblePayments.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.finalPayment36 ? thousandNotationAndCeil(data.finalPayment36) : '')});
      } else if (data.maxDuration===24) {
        visibleMonths.push({alignment: "center", bold: true, color: "white", fontSize: 16, text: "12 maanden"});
        visibleMonths.push({alignment: "center", bold: true, color: "white", fontSize: 16, text: "24 maanden"});
        visibleMonthsValues.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.monthlyAmount12 ? thousandNotationAndCeil(data.monthlyAmount12) : '')});
        visibleMonthsValues.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.monthlyAmount24 ? thousandNotationAndCeil(data.monthlyAmount24) : '')});
        visiblePayments.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.finalPayment12 ? thousandNotationAndCeil(data.finalPayment12) : '')});
        visiblePayments.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.finalPayment24 ? thousandNotationAndCeil(data.finalPayment24) : '')});
      } else {
        visibleMonthsValues.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.monthlyAmount12 ? thousandNotationAndCeil(data.monthlyAmount12) : '')});
        visibleMonths.push({alignment: "center", bold: true, color: "white", fontSize: 16, text: "12 maanden"});
        visiblePayments.push({color: "white", fontSize: 16, bold: true, alignment: "center", text: "\u20AC "+(data.finalPayment12 ? thousandNotationAndCeil(data.finalPayment12) : '')});
     }
      setHighest3Months(visibleMonths);
      setHighest3MonthsValues(visibleMonthsValues);
      setHighest3MonthsPayments(visiblePayments);
      setBestChoice(bestChoiceArr);

      var { column1: int_col1, column2: int_col2 } = convertAccessoires(data.accessoryGroups, "Interieur");
      var { column1: ext_col1, column2: ext_col2 } = convertAccessoires(data.accessoryGroups, "Exterieur");
      var { column1: info_col1, column2: info_col2 } = convertAccessoires(data.accessoryGroups, "Infotainment");
      var { column1: mil_col1, column2: mil_col2 } = convertAccessoires(data.accessoryGroups, "Milieu");
      var { column1: o_col1, column2: o_col2 } = convertAccessoires(data.accessoryGroups, "Overige");
 
       var showAccessoires = true;

      setAccessoryGroups({
        exterieur: {columns: [ext_col1, ext_col2]},
        interieur: {columns: [int_col1, int_col2]},
        infotainment: {columns: [info_col1, info_col2]},
        milieu: {columns: [mil_col1, mil_col2]},
        overige: {columns: [o_col1, o_col2]},
        show: showAccessoires
      });



      // console.log(accessoryGroups);
      setIsLoaded(true);
    }).catch(err => console.log(err));
  },[id]);
  
  useEffect(() => {
    if(!isLoaded) return;
    var voertuig = (data.make ? data.make+' ' : '')+(data.model ? data.model+' ' : '')+(data.type ? data.type : '');
    voertuig = voertuig.slice(0, 35);

    var prijzen = [];
    var priceEx = data.priceEx;
    if(!priceEx) priceEx = Math.floor(data.leaseAmount);
    prijzen.push({text: 'Aanschaf (incl. BTW):'}, {text: '\u20AC '+(data.price ? thousandNotationAndCeil(data.price) : ''), bold: true});
    if(!data.price_ex) data.price_ex = data.leaseAmount;
    prijzen.push({text: 'Aanschaf (excl. BTW):'}, {text: '\u20AC '+(priceEx ? thousandNotationAndCeil(priceEx) : ''), bold: true});

    // console.log(prijzen);

    var docDefinition = {
      pageSize: 'A4',
      defaultStyle: {
        font: 'demo',
        fontSize: 11,  
      },
      styles: {
        icon: {
          font: 'Fontello'
        },
      },
      content: [
          {image: "logo", fit: [200,150], alignment: "center"},
          {text: " "},
          {
            layout: 'noBorders',
            table: {
            heights: 290, 
            widths: ['*'],
            body: [
              [{image: "vehicleImage", fit:[520,290], alignment: 'center'}]
            ]
          }},
          {text: " "},
          {text: voertuig, fontSize: 22, bold: true, noWrap: true, width: 70},
          {text: " ", lineHeight: 1.9},
          	    {
			canvas: [
				{
					type: 'rect',
					x: 0,
					y: 0,
					w: 520,
					h: 40,
					r: 5,
					lineColor: '#ccc',
				},
			]
	    },
		{
			table: {
			    widths: [130,130,130,130],
				headerRows: 1,
				body: [
					prijzen
				]
			},
			layout: 'noBorders',
			absolutePosition: {x: 60, y: 508}
		},
          {
            canvas: [
              {
                type: 'rect',
                x: 0,
                y: 50,
                w: 160,
                h: 160,
                r: 5,
                color: '#192954'
              },{
                type: 'rect',
                x: 180,
                y: 50,
                w: 160,
                h: 160,
                r: 5,
                color: '#275eac'
              },{
                type: 'rect',
                x: 360,
                y: 50,
                w: 160,
                h: 160,
                r: 5,
                color: '#0ca6cf'
              }
            ]
            },
          {
            absolutePosition: {x: 40, y: 540},
            layout: 'noBorders',
            table: {
              widths: [150, 192, 150],
              body: [       
                [{text: " ", lineHeight: 0.5},{text: " ", lineHeight: 0.5},{text: " ", lineHeight: 0.5}], 
                ...bestChoice,
                [{text: " ", lineHeight: 1},{text: " ", lineHeight: 1},{text: " ", lineHeight: 1}],
                [{text: "Termijn", color: "white", alignment: "center", fontSize: 11, lineHeight: 0.8},{text: "Termijn", color: "white", alignment: "center", fontSize: 11, lineHeight: 0.8},{text: "Termijn", color: "white", alignment: "center", fontSize: 11, lineHeight: 0.8}],
                [...highest3Months],
                [{text: " ", lineHeight: 0.5},{text: " ", lineHeight: 0.5},{text: " ", lineHeight: 0.5}],
                [{text: "Maandbedrag", color: "white", alignment: "center", fontSize: 11, lineHeight: 0.8},{text: "Maandbedrag", color: "white", alignment: "center", fontSize: 11, lineHeight: 0.8},{text: "Maandbedrag", color: "white", alignment: "center", fontSize: 11, lineHeight: 0.8}],
                [...highest3MonthsValues],
                [{text: " ", lineHeight: 0.5},{text: " ", lineHeight: 0.5},{text: " ", lineHeight: 0.5}],
                [{text: "Slottermijn", color: "white", alignment: "center", fontSize: 11, lineHeight: 0.8},{text: "Slottermijn", color: "white", alignment: "center", fontSize: 11, lineHeight: 0.8},{text: "Slottermijn", color: "white", alignment: "center", fontSize: 11, lineHeight: 0.8}],
                [...highest3MonthsPayments],
              ]
            }
        },
        {text: voertuig, fontSize: 22, bold: true, lineHeight: 2, pageBreak: "before"},
        {
          columns: [
            {text: "Voertuig", fontSize: 16, bold: true, lineHeight: 1.4},
            {text: "Motor en transmissie", fontSize: 16, bold: true, lineHeight: 1.4}
          ]
        },
        {
          columns: [
            {
              layout: 'lightHorizontalLines', // optional
              table: {
                widths: [100, 100],
                body: [ 
                  [{text: "Merk", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.make ? data.make : ""), lineHeight: 1.2, fontSize: 11}],
                  [{text: "Model", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.model ? data.model : ""), lineHeight: 1.2, fontSize: 11}],
                  [{text: "Kenteken", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.licensePlate ? data.licensePlate : ""), lineHeight: 1.2, fontSize: 11}],
                  [{text: "Km. stand", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.mileage ? data.mileage+" km" : ""), lineHeight: 1.2, fontSize: 11}],
                  [{text: "Bouwjaar", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.constructionYear ? data.constructionYear : ""), lineHeight: 1.2, fontSize: 11}],
                  [{text: "Kleur", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.baseColor ? data.baseColor : ""), lineHeight: 1.2, fontSize: 11}],
                  [{text: "Carrosserie", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.carrosserie ? data.carrosserie : ""), lineHeight: 1.2, fontSize: 11}],
                  [{text: "Aantal deuren", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.noOfDoors ? data.noOfDoors : ""), lineHeight: 1.2, fontSize: 11}],
                  [{text: "Aantal zitplaatsen", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.noOfSeats ? data.noOfSeats : ""), lineHeight: 1.2, fontSize: 11}],
                ]
              }
            },
            {
              layout: 'lightHorizontalLines', // optional
              table: {
                widths: [100, 100],
                body: [ 
                  [{text: "Brandstof", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.fuelType ? friendlyFuelType(data.fuelType) : ""), lineHeight: 1.2, fontSize: 11}],
                  [{text: "Motor vermogen", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.engineHP ? data.engineHP+"PK ": "")+(data.powerMotorKW ? "("+data.powerMotorKW+" kW)" : ""), lineHeight: 1.2, fontSize: 11}],
                  [{text: "Transmissie", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.transmission ? friendlyTransmission(data.transmission) : ""), lineHeight: 1.2, fontSize: 11}],
                  [{text: "Aantal versnellingen", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.noOfGears ? data.noOfGears : ""), lineHeight: 1.2, fontSize: 11}],
                  [{text: "Cilinderinhoud", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.cylinderCapacity ? data.cylinderCapacity+"cc" : ""), lineHeight: 1.2, fontSize: 11}],
                  [{text: "Aantal cilinders", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.cylinderNumber ? data.cylinderNumber : ""), lineHeight: 1.2, fontSize: 11}],
                ]
              }
            },
          ],
        },
        {text: " ", lineHeight: 2},
        {
          columns: [
            {text: "Verbruik, prestaties en emissie", fontSize: 16, bold: true, lineHeight: 1.4},
            {text: "Gewicht en laadvermogen", fontSize: 16, bold: true, lineHeight: 1.4}
          ]
        },
        {
          columns: [
            {
              layout: 'lightHorizontalLines', // optional
              table: {
                widths: [100, 100],
                body: [ 
                  [{text: "Gemiddeld verbruik", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.averageConsumption ? data.averageConsumption+"l/100km" : ""), lineHeight: 1.2, fontSize: 11}],
                  [{text: "Topsnelheid", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.topSpeed ? data.topSpeed+"km/u" : ""), lineHeight: 1.2, fontSize: 11}],
                  [{text: "Acceleratie", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.acceleration ? data.acceleration+" seconden" : ""), lineHeight: 1.2, fontSize: 11}],
                  [{text: "CO2-emissie", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.fineDustEmissions ? data.fineDustEmissions : ""), lineHeight: 1.2, fontSize: 11}],
                  [{text: "Energielabel", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.energyLabel ? data.energyLabel : ""), lineHeight: 1.2, fontSize: 11}],
                ]
              }
            },
            {
              layout: 'lightHorizontalLines', // optional
              table: {
                widths: [100, 100],
                body: [ 
                  [{text: "Tankinhoud", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.tankCapacity ? data.tankCapacity+" liter" : ""), lineHeight: 1.2, fontSize: 11}],
                  [{text: "Laadvermogen", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.payload ? data.payload+" kg" : ""), lineHeight: 1.2, fontSize: 11}],
                  [{text: "Gewicht", bold: true, fontSize: 11, lineHeight: 1.2}, {text: (data.mass ? data.mass+" kg" : ""), lineHeight: 1.2, fontSize: 11}],
                ]
              }
            },
          ],
        },
        (accessoryGroups.show) && {text: "Overzicht van alle opties", fontSize: 22, bold: true, pageBreak: "before"},
        (accessoryGroups.interieur && {text: " "}),
        (accessoryGroups.interieur && {text: "Interieur", bold: true, fontSize: 16, lineHeight: 1.5}),
        (accessoryGroups.interieur ? (accessoryGroups.interieur) : ""),

        (accessoryGroups.exterieur && {text: " "}),
        (accessoryGroups.exterieur && {text: "Exterieur", bold: true, fontSize: 16, lineHeight: 1.5}),
        (accessoryGroups.exterieur ? (accessoryGroups.exterieur) : ""),


        (accessoryGroups.milieu && {text: " "}),
        (accessoryGroups.milieu && {text: "Milieu", bold: true, fontSize: 16, lineHeight: 1.5}),
        (accessoryGroups.milieu ? (accessoryGroups.milieu) : ""),

        (accessoryGroups.infotainment && {text: " "}),
        (accessoryGroups.infotainment && {text: "Infotainment", bold: true, fontSize: 16, lineHeight: 1.5}),
        (accessoryGroups.infotainment ? (accessoryGroups.infotainment) : ""),

        (accessoryGroups.overige && {text: " "}),
        (accessoryGroups.overige && {text: "Overige", bold: true, fontSize: 16, lineHeight: 1.5}),
        (accessoryGroups.overige ? (accessoryGroups.overige) : ""),
        
      ],
      images: {
        vehicleImage: {
          // url: vehicleImage+"?"+new Date().getTime(), //"http://localhost:3000/pexels-photo-170811.jpg",
          url: vehicleImage+"?"+new Date().getTime(),
          headers: {
            "Access-Control-Allow-Origin": "*"
          }
        },
        logo: "https://brochure.sld.nl/SLD_logo.png"
      }
    };
    // pdfMake.createPdf(docDefinition).download(voertuig.replace(/\.+/g,""));
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getDataUrl((dataUrl) => {
      const targetElement = document.querySelector('#iframeContainer');
      const iframe = document.querySelector("iframe"); // document.createElement('iframe');
      iframe.src = dataUrl;
      targetElement.appendChild(iframe);
    });

  },[isLoaded, data]);


  return (
    <div id="iframeContainer" style={{width: "100%", height: "100%"}}>
      <iframe width="100%" style={{height: "100vh"}}></iframe>
    </div>
  );
}

export default App;
