export const getBase64ImageFromURL = (url, retryCounter=1) => {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
  
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
  
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
  
        var dataURL = canvas.toDataURL("image/png");
  
        resolve(dataURL);
      };
  
      img.onerror = error => {
        console.log("image error..", error);
        if(retryCounter>2) {
          reject(error);
          return;
        };
        retryCounter = retryCounter + 1;
        getBase64ImageFromURL(url, retryCounter);
      };
  
      img.src = url;
    });
  }

  export const convertAccessoires = (inputArr, groupName) => {
    var accessoires = inputArr.filter(item => item.name===groupName).map(item => ({text: [{ text: '\uF14A ', style: 'icon', color: "#00C128"},{text: item.description, fontSize: 10, lineHeight: 1.5}]}));
    var column1 = (accessoires.length>1) ? accessoires.slice(0, (accessoires.length/2)) : accessoires;
    var column2 = accessoires.slice((accessoires.length/2));
 
    return {
      column1,
      column2
    }
  }