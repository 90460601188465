import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const rootDiv = document.getElementById("root");
const root = ReactDOM.createRoot(rootDiv); 

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="*" element={<App />}></Route>
  )
);

root.render(
    <RouterProvider router={router} />
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
