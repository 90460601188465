export const friendlyFuelType = (fuelType) => {
    if(fuelType==="D") return "Diesel";
    if(fuelType==="B") return "Benzine";
    if(fuelType==="3") return "LPG G3";
    if(fuelType==="E") return "Elektrisch";
    if(fuelType==="H") return "Hybride";
    if(fuelType==="BE") return "Hybride Benzine";
    if(fuelType==="DE") return "Hybride Diesel";
    if(fuelType==="A") return "Aardgas";
    if(fuelType==="8") return "Benzine/E85";
    if(fuelType==="O") return "Overig";
    if(fuelType) return fuelType;
    if(!fuelType) return "Onbekend";
}

export const friendlyTransmission = (transmision) => {
    if(transmision==="A") return "Automaat";
    if(transmision==="H") return "Handgeschakeld";
    if(!transmision) return "Onbekend";
}

export const friendlyVatMargin = (vatMargin) => {
    if(vatMargin==="M") return "Marge-auto";
    if(/^(V|B)$/.test(vatMargin)) return "BTW-auto";
    if(vatMargin) return vatMargin;
    if(!vatMargin) return "Onbekend";
}

export const friendlyAutoCategory = (input) =>{
    if(input==="AUTO") return "Personenauto";
    if(input==="BEDRIJF") return "Bedrijfswagen";
    if(input) return input;
    if(!input) return "Onbekend";
}

export const formatThousandNotation = (inputValue) => {
    if(!inputValue) return;
    inputValue = "" + inputValue;
    inputValue = inputValue.replace(/\./g,"");
    var f = /[,.](\d+)$/.exec(inputValue);
    inputValue = inputValue.replace(/[,.]\d+$/, "");
    var e = /^-/.test(inputValue);
    inputValue = inputValue.replace(/^-/, "");
    var d = inputValue.split("").reverse();
    inputValue = [];
    for (var a = 0; a < d.length; a++) {
        if (a && !(a % 3)) {
            inputValue.push(".")
        }
        inputValue.push(d[a])
    }
    var c = (e ? "-" : "") + inputValue.reverse().join("") + (f ? "," + f[1] : "");
    return c;
}
export const thousandNotationAndCeil = (inputValue) => {
    if(typeof inputValue==="string") inputValue = +inputValue;
    var roundedUp = Math.ceil(inputValue);
    var thousand = formatThousandNotation(roundedUp);
    return thousand;
}